chip-tmpl {
  display: inline-block;
  margin: 0 5px 5px 0; }

.default-chip {
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  background: #e0e0e0;
  padding: 5px; }

chip-tmpl:focus {
  outline: none; }
  chip-tmpl:focus .default-chip {
    border: 2px solid #9e9e9e;
    background: #9e9e9e;
    color: white; }

.chip-failed .default-chip {
  color: red; }

chips {
  display: block;
  padding: 5px; }
  chips > div {
    display: inline; }
    chips > div > input {
      height: 100%;
      border: none;
      font-size: 14px; }
    chips > div > input:focus {
      outline: none; }

.chip-out-focus {
  border-bottom: 1px solid #e0e0e0; }

.chip-in-focus {
  border-bottom: 1px solid #106cc8; }
